import { ConfigSite, JoinPageProduct } from 'src/shared/types';
import { BillerById } from 'src/shared/constants/billers';

export const pushSelectItemToGoogleAnalytics = (
  affiliateCode: string | undefined,
  promoCode: string | null,
  product: JoinPageProduct,
  site: ConfigSite,
) => {
  window.dataLayer.push({
    event: 'select_item',
    ecommerce: {
      affiliate_id: affiliateCode,
      coupon: promoCode,
      currency: 'USD',
      items: [
        {
          index: 1,
          item_brand: site.name,
          item_category: 'subscription',
          item_id: product.productUUID,
          item_name: product.description,
          price: product.initialPriceOriginal,
          quantity: 1,
        },
      ],
      source: 'Join Page',
    },
  });

  window.dataLayer.push({
    event: 'select_item_ua',
    ecommerce: {
      currency: 'USD',
      click: {
        actionField: {
          source: 'Join Page',
        },
        products: [
          {
            brand: site.name,
            category: 'subscription',
            id: product.productUUID,
            index: 1,
            name: product.description,
            price: product.initialPriceOriginal,
            quantity: 1,
          },
        ],
      },
    },
  });
};

export const pushBeginCheckoutToGoogleAnalytics = (
  affiliateCode: string | undefined,
  product: JoinPageProduct,
  promoCode: string | null,
  site: ConfigSite,
) => {
  const billerName = BillerById[product.billerID];

  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      affiliate_id: affiliateCode,
      source: 'Join Page',
      value: product.initialPriceOriginal,
      tax: '',
      currency: 'USD',
      coupon: promoCode,
      payment_method: billerName,
      items: [
        {
          item_name: product.description,
          item_id: product.productUUID,
          item_brand: site.name,
          item_category: 'subscription',
          quantity: 1,
          price: product.initialPriceOriginal,
        },
      ],
    },
  });

  window.dataLayer.push({
    event: 'begin_checkout_ua',
    ecommerce: {
      currency: 'USD',
      checkout: {
        actionField: {
          affiliate_id: affiliateCode,
          source: 'Join Page',
          revenue: product.initialPriceOriginal,
          tax: '',
          coupon: promoCode,
          option: billerName,
        },
        products: [
          {
            name: product.description,
            id: product.productUUID,
            brand: site.name,
            category: 'subscription',
            quantity: 1,
            price: product.initialPriceOriginal,
          },
        ],
      },
    },
  });
};

import React from 'react';
import styled from 'styled-components/macro';
import numberToWords from 'number-to-words';
import { DESKTOP } from 'src/client/helpers/mediaQueries';
import { useAppData } from 'src/client/helpers/useAppData';
import { StoredOrderData } from 'src/shared/types/OrderResponse';
import { getData } from 'src/client/helpers/utils';
import { cartStorageKey } from 'src/client/helpers/constants';

function formatCurrencyInWords(amount: number): string {
  let dollars = Math.floor(amount);
  let cents = Math.round((amount - dollars) * 100);

  if (cents >= 100) {
    dollars += Math.floor(cents / 100);
    cents %= 100;
  }

  const dollarsText = numberToWords.toWords(dollars);

  if (cents > 0) {
    const centsText = numberToWords.toWords(cents);
    return `${dollarsText} dollars and ${centsText} cents`;
  } else {
    return `${dollarsText} dollars`;
  }
}

export const VatPurchaseInfo = () => {
  const { cdnUrl, site } = useAppData();
  const storedData = getData<StoredOrderData>(cartStorageKey);

  if (!storedData?.originalProduct) return null;

  const { originalProduct } = storedData;

  if (!originalProduct.vatPct || parseFloat(originalProduct.vatPct) <= 0) {
    return null;
  }

  const vat = +originalProduct.initialPriceOriginal * parseFloat(originalProduct.vatPct);
  const priceWithVat = +originalProduct.initialPriceOriginal + vat;

  const initialPeriodWords = numberToWords.toWords(originalProduct.initialPeriod) + ' days';
  const initialPriceWords = formatCurrencyInWords(+originalProduct.initialPriceOriginal);
  const vatWords = formatCurrencyInWords(vat);
  const priceWithVatWords = formatCurrencyInWords(priceWithVat);
  const recurringPeriodWords = originalProduct.recurringPeriod
    ? numberToWords.toWords(originalProduct.recurringPeriod) + ' days'
    : null;

  return (
    <VatPurchaseInfoContainer>
      <VatPurchaseInfoTitle>Purchase information</VatPurchaseInfoTitle>
      <VatPurchaseInfoMessage>
        By proceeding with and finalizing this transaction, you hereby acknowledge, understand, and fully consent to be
        bound by all the conditions, stipulations, and provisions outlined in the terms of service, as they apply to
        this agreement.
        <br />
        {site.domain} for {initialPeriodWords} at {initialPriceWords} plus {vatWords} VAT equals {priceWithVatWords}
        {recurringPeriodWords ? `, recurring every ${recurringPeriodWords}` : ''}. You can cancel this subscription at
        anytime by going to "Manage Account"
        <br />
        This charge will show on your statement as <strong>'ECHST.NET 866-452-5108'</strong>.
      </VatPurchaseInfoMessage>
      <VatPurchaseSecurityIcons src={`${cdnUrl}/join/vat-security-icons.png`} alt="security features" />
    </VatPurchaseInfoContainer>
  );
};

const VatPurchaseInfoContainer = styled.div`
  order: 3;
  position: static;
  width: 100%;

  @media ${DESKTOP} {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
  }
`;

const VatPurchaseInfoTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0;
`;

const VatPurchaseInfoMessage = styled.p`
  margin: 0 auto;
  font-size: 10px;
`;

const VatPurchaseSecurityIcons = styled.img`
  display: block;
  margin: 25px auto 0;

  @media ${DESKTOP} {
    margin: 25px 0 0;
  }
`;

import styled, { css } from 'styled-components/macro';

// Media queries for mobile first design.
// Just override styles as the page gets larger.

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default

/**
 * Small devices (landscape phones, 576px and up)
 */
export const MOBILE_LANDSCAPE = '(min-width: 576px)';

/**
 * Medium devices (tablets (portrait), 768px and up)
 */
export const TABLET_PORTRAIT = '(min-width: 768px)';

/**
 * Large devices (tablets (lanescape), small desktops, 992px and up)
 */
export const TABLET_LANDSCAPE = '(min-width: 992px)';

/**
 * Large devices (desktops, 992px and up)
 */
export const DESKTOP = '(min-width: 1200px)';

/**
 * XX-Large devices (larger desktops, 1400px and up)
 */
export const DESKTOP_WIDE = '(min-width: 1400px)';

export const mediaBreakpoints = {
  sm: MOBILE_LANDSCAPE,
  md: TABLET_PORTRAIT,
  lg: TABLET_LANDSCAPE,
  xl: DESKTOP,
  xxl: DESKTOP_WIDE,
};

export const maxWidthMediaQueries = css`
  margin-left: 10px;
  margin-right: 10px;
  max-width: 100%;

  @media ${MOBILE_LANDSCAPE} {
    margin-left: auto;
    margin-right: auto;
    max-width: 540px;
  }

  @media ${TABLET_PORTRAIT} {
    max-width: 720px;
  }

  @media ${TABLET_LANDSCAPE} {
    max-width: 960px;
  }

  @media ${DESKTOP} {
    max-width: 1140px;
  }

  @media ${DESKTOP_WIDE} {
    max-width: 1320px;
  }
`;

export const ConstrainedContainer = styled.section`
  ${maxWidthMediaQueries}
`;

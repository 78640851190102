import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { MOBILE_LANDSCAPE, TABLET_LANDSCAPE, TABLET_PORTRAIT } from 'src/client/helpers/mediaQueries';
import { getData } from 'src/client/helpers/utils';
import { cartStorageKey } from 'src/client/helpers/constants';
import { StoredOrderData } from 'src/shared/types/OrderResponse';
import { VatPurchaseInfo } from './VatPurchaseInfo';
import { ProductInfo } from './ProductInfo';

type Props = {
  simpleAmount?: boolean;
  iframeUrl: string;
  validationRequired?: boolean;
};

export const PurchaseFrame = (props: Props) => {
  const { iframeUrl, simpleAmount, validationRequired } = props;
  const storedData = getData<StoredOrderData>(cartStorageKey);

  const vatEnabled = storedData?.originalProduct?.vatPct && parseFloat(storedData.originalProduct.vatPct) > 0;

  const location = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const iframeParams = useMemo(() => new URL(iframeUrl).searchParams, [iframeUrl]);

  let amount;

  if (simpleAmount) {
    amount = queryParams.has('amount')
      ? `${queryParams.get('amount')} ${queryParams.get('currency')}`
      : `${iframeParams.get('amount')} ${iframeParams.get('currency')}`;
  }

  return (
    <FrameWrapper>
      <Title>
        <FormattedMessage id="purchaseframe.payment_info" />
      </Title>
      {!simpleAmount && !vatEnabled && <ProductInfo />}
      {validationRequired && (
        <p>
          <FormattedMessage id="purchaseframe.validation_required" />
        </p>
      )}
      <OrderedSection>
        <IFrame title="Purchase" src={iframeUrl} />
        {amount && (
          <RocketGateText className="pps">
            <FormattedMessage id="purchaseframe.charge_amount" values={{ amount }} />
          </RocketGateText>
        )}
        <VatPurchaseInfo />
      </OrderedSection>
    </FrameWrapper>
  );
};

const FrameWrapper = styled.div`
  width: 100%;

  @media ${TABLET_LANDSCAPE} {
    height: 100%;
  }
`;

const Title = styled.h1`
  display: flex;
  font-size: 25px;
  font-weight: 300;
  text-align: center;
  flex-direction: row;

  @media ${MOBILE_LANDSCAPE} {
    font-size: 30px;
  }

  :before,
  :after {
    border-bottom: 1px solid #e5e0e0;
    content: '';
    flex: 1 1;
    margin: auto 12px;

    @media (min-width: 576px) {
      margin: auto;
    }
  }

  :before {
    margin-right: 16px;
  }

  :after {
    margin-left: 16px;
  }
`;

const IFrame = styled.iframe.attrs((props) => ({
  isNewForm: props.src?.includes('-new.css'),
}))`
  border: 0;
  height: 100%;
  width: 100%;
  min-height: ${({ isNewForm }) => (isNewForm ? '670px' : '525px')};
  margin: 0 auto;

  @media ${TABLET_PORTRAIT} {
    width: ${({ isNewForm }) => (isNewForm ? '560px' : '100%')};
    min-height: ${({ isNewForm }) => (isNewForm ? '445px' : '375px')};
  }
`;

const RocketGateText = styled.p`
  margin: 0;
  text-align: center;

  @media ${TABLET_LANDSCAPE} {
    font-family: Arial, sans-serif;
    font-size: 12px;
    order: 2;
  }
`;

const OrderedSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${IFrame} {
    order: 2;

    @media ${TABLET_LANDSCAPE} {
      order: 1;
    }
  }

  .pps {
    order: 1;
  }
`;

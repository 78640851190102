import { useIntl } from 'react-intl';
import strings from 'src/shared/translations/en.json';
import { JoinPageProduct } from 'src/shared/types';

const initialPeriodMessages: Record<number, keyof typeof strings> = {
  0: 'subscription.choice.subscription.lifetime',
  30: 'subscription.choice.subscription.monthly',
  360: 'subscription.choice.subscription.yearly',
  365: 'subscription.choice.subscription.yearly',
};

const initialDaysPluralMap: Record<string, keyof typeof strings> = {
  one: 'subscription.choice.initial_days_one_plural',
  two: 'subscription.choice.initial_days_two_plural',
  few: 'subscription.choice.initial_days_few_plural',
  many: 'subscription.choice.initial_days_many_plural',
  other: 'subscription.choice.initial_days_other_plural',
};

export const useSubscriptionInitialPeriod = (product: JoinPageProduct) => {
  const { formatMessage, formatPlural } = useIntl();
  const { initialPeriod, recurringPeriod } = product;

  let initialPeriodID = initialPeriodMessages[initialPeriod];

  // If there is no recurring period, we have some different messages for the
  // initial period.
  if (recurringPeriod === 0) {
    // 30 days = 1 month
    if (initialPeriod === 30) {
      initialPeriodID = 'subscription.choice.subscription.one_month';
    }

    // 365 or 360 days = 1 year because reasons
    if (initialPeriod === 365 || initialPeriod === 360) {
      initialPeriodID = 'subscription.choice.subscription.one_year';
    }
  }

  // There are some arbitrary day length subscriptions available
  // so if we don't find a match, we just default it to 'x days'.
  if (!initialPeriodID) {
    initialPeriodID = initialDaysPluralMap[formatPlural(initialPeriod)];
  }

  return formatMessage({ id: initialPeriodID }, { period: initialPeriod });
};

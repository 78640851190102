import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import cx from 'classnames';
import { DESKTOP, TABLET_LANDSCAPE } from 'src/client/helpers/mediaQueries';

const cryptoServices = ['BTC'];
const yearlyPeriods = [365, 360];

type Props = {
  amount: string;
  cents?: string;
  currency: string;
  currencySymbol: string;
  recurringPeriod: number;
};

export const Price = (props: Props) => {
  const { amount, cents = '00', currency, currencySymbol, recurringPeriod } = props;
  const crypto = currency ? cryptoServices.includes(currency.toUpperCase()) : false;
  const intl = useIntl();

  const perMonth = yearlyPeriods.includes(recurringPeriod)
    ? intl.formatMessage({ id: 'subscription.choice.per_month' })
    : '';

  return (
    <PriceWrapper className={cx({ crypto })}>
      <Currency>{currencySymbol}</Currency>
      <Dollars>{amount}</Dollars>
      <PriceDetails>
        <Cents className="cents">{cents}</Cents>
        <PerMonth hidden={!perMonth}>{perMonth}</PerMonth>
      </PriceDetails>
    </PriceWrapper>
  );
};

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 15px 0;
  position: relative;
  line-height: 1;
  vertical-align: text-top;

  .crypto & {
    font-size: 1.55rem;

    @media (min-width: 1200px) {
      font-size: 2.25rem;
    }
  }
`;

const Currency = styled.div`
  font-size: 2rem;

  @media ${TABLET_LANDSCAPE} {
    font-size: 1.6rem;
  }

  @media ${DESKTOP} {
    font-size: 1.9rem;
  }

  .crypto & {
    align-self: center;
    margin-right: 0.5rem;
  }
`;

const Cents = styled.div`
  font-size: 2rem;

  .crypto &:before {
    content: '.';
  }
`;

// Line height adjustment to align the top of the cents and currency symbol
// with the top of the dollar amount.
const Dollars = styled.span`
  font-size: 3.5rem;
  line-height: 3rem;

  .crypto & {
    font-size: inherit;
    line-height: inherit;
  }
`;

const PerMonth = styled.small`
  font-size: 1rem;
`;

const PriceDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

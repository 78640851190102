import React, { useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { CartPage, MembersPortal, PurchaseExternal, SuccessPage, UpgradesPage, UpsalesPage } from './pages';
import { CookieMonster } from './components/CookieMonster/CookieMonster';
import { AuthFrame } from './components/AuthFrame/AuthFrame';
import { AgeVerificationCallback } from './pages/AgeVerification/AgeVerificationCallback';
import { constants, useAppData, useExternalScript, useGoogleTagManager } from './helpers';
import { AppLoader, HTMLHead } from './components';
import { UserRoute } from './components/UserRoute';
import { useAnalyticsPageView } from './components/Analytics/useAnalyticsPageView';
import { PreJoinPageAB } from './helpers/ABSwitchComponent';
import { useAnalyticsUniqueUUID } from './components/Analytics/useAnalyticsUniqueUUID';
import { getCategoryIdByCampaignName } from './helpers/utils';
import { AbPreJoinPageUrls } from '../shared/constants/abTests';
import { RedirectWithSameParams } from './components/RedirectWithSameParams';

const { routerPaths } = constants;

export const App = () => {
  const [userLoaded, setUserLoaded] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const campaign = query.get('campaign');

  const { externalScripts, loading, site, abTests } = useAppData();

  const preJoinAbVariant = abTests?.['PreJoinPage']?.variant;
  const abTestPreJoinUrl = preJoinAbVariant ? AbPreJoinPageUrls[preJoinAbVariant] : undefined;

  useExternalScript(
    externalScripts?.trafficjunky?.url,
    externalScripts?.trafficjunky?.code,
    externalScripts?.trafficjunky?.appendCodeAfterUrlLoads,
    externalScripts?.trafficjunky?.mamWhitelist,
  );

  // strip out `joinUserLogin` query param so that it doesn't cause a user to be redirected to main site by accident
  if (query.has('joinUserLogin')) {
    query.delete('joinUserLogin');
    const querystring = query.toString();
    history.replace(`${location.pathname}${querystring ? `?${querystring}` : ''}`);
  }

  useGoogleTagManager(userLoaded);

  // This hook is used to measure our free/guest members, and their conversion rates
  useAnalyticsUniqueUUID();
  useAnalyticsPageView({
    categoryId: getCategoryIdByCampaignName(campaign),
  });

  if (loading || !site) {
    return null;
  }

  // @TODO - 2023-10-04
  // Added commented out as sort of an 'a/b' test to investigate a revenue drop
  // If you're reading this in a couple months, find out of this code is ever coming back

  // A bunch of states are joining the anti-porn-party.
  // We have a slightly more sophisticated way of handling this.
  // See `src/server/api/get/appData.ts` for more info.
  // const isUserAllowed = isUser || parseInt(cookies.avr, 10) === 1;
  // const blockAccess = showLegaLRestrictionSplash && !isUserAllowed;

  // if (blockAccess) {
  //   return (
  //     <>
  //       <LegalSplashPage />
  //     </>
  //   );
  // }

  return (
    <>
      <AuthFrame setUserLoaded={setUserLoaded} />
      <HTMLHead />
      <AppLoader />

      {userLoaded && (
        <Switch>
          {/* Purchase Pages */}
          <Route exact path={routerPaths.purchase.success} component={SuccessPage} />
          <UserRoute exact path={routerPaths.purchase.upsales} component={UpsalesPage} />
          <Route exact path={routerPaths.purchase.gallery}>
            <PurchaseExternal type="gallery" />
          </Route>
          <Route exact path={routerPaths.purchase.upgrade}>
            <PurchaseExternal type="site" />
          </Route>

          {/* Upsales Page */}
          <Route exact path={routerPaths.gateway} component={UpsalesPage} />

          {/* Upgrades Pages */}
          <UserRoute exact path={routerPaths.upgrades.sites} component={UpgradesPage} />
          <UserRoute exact path={routerPaths.upgrades.checkout} component={CartPage} />
          <UserRoute exact path={routerPaths.membersPortal.page} component={MembersPortal} />
          <UserRoute exact path={routerPaths.membersPortal.checkout} component={CartPage} />

          {/* Pre-Join (Main Join Page) */}
          <Route exact path={routerPaths.purchase.site} component={PreJoinPageAB} />
          <Route strict path={routerPaths.nonNude.path} component={PreJoinPageAB} />
          {abTestPreJoinUrl ? (
            <>
              <RedirectWithSameParams exact from="/" to={abTestPreJoinUrl} />
              <Route exact path={abTestPreJoinUrl} component={PreJoinPageAB} />
            </>
          ) : (
            <Route exact path="/" component={PreJoinPageAB} />
          )}

          {/* AgeVerify */}
          <Route exact path={routerPaths.ageVerification.callback} component={AgeVerificationCallback} />

          {/* Catch-All */}
          <Route path="*" render={() => <Redirect to="/" />} />
        </Switch>
      )}
      <CookieMonster />
    </>
  );
};

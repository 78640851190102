import { useIntl } from 'react-intl';
import strings from 'src/shared/translations/en.json';
import { JoinPageProduct } from 'src/shared/types';

const recurringMessages: Record<number, keyof typeof strings> = {
  365: 'subscription.choice.rebills_years_one_plural',
  360: 'subscription.choice.rebills_years_one_plural',
  30: 'subscription.choice.rebills_months_one_plural',
  0: 'subscription.choice.one_time_purchase',
};

const rebillDaysPluralMap: Record<string, keyof typeof strings> = {
  one: 'subscription.choice.rebills_days_one_plural',
  two: 'subscription.choice.rebills_days_two_plural',
  few: 'subscription.choice.rebills_days_few_plural',
  many: 'subscription.choice.rebills_days_many_plural',
  other: 'subscription.choice.rebills_days_other_plural',
};

export const useSubscriptionRebillPeriod = (product: JoinPageProduct) => {
  const { formatMessage, formatPlural } = useIntl();

  const { currencySymbol, recurringPrice, recurringPeriod } = product;
  const [, recurringCurrencyPrice] = product.recurringCurrencyPrice.split(' ');

  const vatPct = parseFloat(product.vatPct);

  const messageValues = {
    period: recurringPeriod,
    price: vatPct > 0 ? `${currencySymbol}${recurringCurrencyPrice}` : `${currencySymbol}${recurringPrice}`,
  };

  // Some of the periods we have specific messages for, use those
  if (recurringMessages[recurringPeriod]) {
    return formatMessage({ id: recurringMessages[recurringPeriod] }, messageValues);
  }

  // This message is for an arbitrary number of days, so we need to  use the
  //  plural rules to determine which message to use.
  const pluralType = formatPlural(recurringPeriod);
  return formatMessage({ id: rebillDaysPluralMap[pluralType] }, messageValues);
};
